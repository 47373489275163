<template>
    <DataTable :value="actInfoDatas" class="p-datatable-houseInfo" selectionMode="single" v-model:contextMenuSelection="selectedrow" @rowContextmenu="onRowContextMenu" responsiveLayout="scroll" dataKey="activationID" @rowSelect="actInfoRowSelected"
               :paginator="true" :rows="20" :filters="filters" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
               :rowsPerPageOptions="[20,40,90]" :loading="isActLoading" sortField="actInviteDate" :sortOrder="-1">
        <template #header>
            <Toast />
            <div class="p-d-flex p-jc-between p-ai-center">
                <h4 class="p-m-0">Activation Information</h4>
                <span class="p-input-icon-left">
                    <i class="pi pi-search" />
                    <!--<InputText placeholder="Keyword Search" />-->
                    <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                    <!--<InputText v-model="filters['global'].value" placeholder="Keyword Search" />-->
                </span>
            </div>
        </template>
        <template #empty>
            No Activation information found.
        </template>
        <template #loading>
            Loading Activation information data. Please wait.
        </template>
        <Column field="activationID" header="Activation ID" :sortable="true"></Column>
        <Column field="userEmail" header="User Email" :sortable="true"></Column>
        <Column field="actInviteDate" header="Invite Date" dataType="date" :sortable="true">
            <template #body="slotProps">
                {{$filters.formatDate(slotProps.data.actInviteDate)}}
            </template>
        </Column>
        <Column field="activatedDate" header="Activated Date" :sortable="true"></Column>
        <Column field="houseID" header="House ID" :sortable="true"></Column>
        <Column field="isActivated" header="Activated" :sortable="true" headerStyle="text-align: center;" bodyStyle="text-align: center;">
            <template #body="slotProps">
                <span :class="'housedata-badge status-' + slotProps.data.isActivated">{{$filters.formatActiveState(slotProps.data.isActivated)}}</span>
            </template>
        </Column>
        <Column field="createdOn" header="Created On" :sortable="true" dataType="date" headerStyle="width: 12%">
            <template #body="slotProps">
                {{$filters.formatDate(slotProps.data.createdOn)}}
            </template>
        </Column>
        <Column field="updatedOn" header="Updated On" :sortable="true" dataType="date" headerStyle="width: 12%">
            <template #body="slotProps">
                {{$filters.formatDate(slotProps.data.updatedOn)}}
            </template>
        </Column>
    </DataTable>
    <ContextMenu :model="menuModel" ref="cm" />
</template>
<script>
  import PostService from '../services/post-services';
  import { ref, onMounted } from "vue";
import { FilterMatchMode } from 'primevue/api';

  export default {
    name: "ActivationInfo",
        setup() {
            const cm = ref();
            const actInfoDatas = ref(null);
            const selectedUser = ref(null);
            const isActLoading = ref(false);
            const filters = ref({
                global: { value: null, matchMode: FilterMatchMode.CONTAINS }
            })
      const getUserActInfo = () => {
        isActLoading.value = true;

        PostService.ActUserInfo().then(res => {
            actInfoDatas.value = res.data;
            console.log("actInfoDatas.value", actInfoDatas.value);
            actInfoDatas.value.forEach(user => { user.actInviteDate = new Date(user.actInviteDate); user.createdOn = new Date(user.createdOn); user.updatedOn = new Date(user.updatedOn) });
        }).catch(() => {
          alert('Error loading data');
        }).finally
        {
          isActLoading.value = false;
        }
      };
        onMounted(getUserActInfo);
        const menuModel = ref([
            { label: 'Resend Activation Email', icon: 'pi pi-fw pi-send', command: () => activationEmail(selectedUser.value.activationID) },
            { label: 'Delete', icon: 'pi pi-fw pi-times', command: () => deleteProduct(selectedUser.value.activationID) }
        ]);
        const onRowContextMenu = (event) => {
            cm.value.show(event.originalEvent);
            selectedUser.value = JSON.parse(JSON.stringify(event.data))
            
            };
            const activationEmail = (user) => {
                PostService.SendActivateEmail(user).then(res => {
                    if (res.data == "Success")
                        alert("Email send");
                    
                }).catch(() => {
                    alert("Error sending Email!");
                });
               
               
            };
            const deleteProduct = (id) => {
                console.log("activation id to delete", id);
                PostService.DeleteActivatedUser(id).then(res => {
                    if (res) {
                        alert("Deleted the user");
                        getUserActInfo();
                    }
                    
                }).catch(() => {
                    alert("Error Loading!")
                });
             
            };
           
            return {
                actInfoDatas, isActLoading, onRowContextMenu, activationEmail, menuModel, cm, deleteProduct, selectedUser, filters
      }
    }
  }
</script>