<template>
  <div class="hello">
    <!-- header section start -->
    <header class="header">
      <div class="container">
        <div class="row flexbox-center">
          <div class="col-lg-2 col-md-3 col-6">
            <div class="logo">
              <a href="#home"><img src="/img/logo.png" alt="logo" /></a>
            </div>
          </div>
          <div class="col-lg-10 col-md-9 col-6 sm-static">
            <div class="responsive-menu"></div>
            <div class="mainmenu">
              <ul id="primary-menu">
                <li><a class="nav-link active" href="#">Home</a></li>
                <li><a class="nav-link" href="#">About</a></li>
                <li><a class="nav-link" href="#">Contact</a></li>
                <!--<li><router-link :to="{ name: 'Admin'}">Admin</router-link></li>-->
                <li><a class="appao-btn" href="#">Download</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header><!-- header section end -->
    <!-- hero area start -->
    <section class="hero-area" id="home">
      <div class="container">
        <div class="row">
          <div class="col-lg-7">
            <div class="hero-area-content">
              <h1>Automate in Your Home</h1>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo </p>
              <a href="#" class="appao-btn">Google Play</a>
              <a href="#" class="appao-btn">App Store</a>
            </div>
          </div>
          <div class="col-lg-5">
            <div class="hand-mockup text-lg-left text-center">
              <img src="img/hand-mockup.png" alt="Hand Mockup" />
            </div>
          </div>
        </div>
      </div>
    </section><!-- hero area end -->
    <!-- about section start -->
    <section class="about-area ptb-90">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="sec-title">
              <h2>About App</h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4">
            <div class="single-about-box">
              <i class="icofont icofont-ruler-pencil"></i>
              <h4>Responsive Design</h4>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text</p>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="single-about-box active">
              <i class="icofont icofont-computer"></i>
              <h4>Fast Performance</h4>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text</p>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="single-about-box">
              <i class="icofont icofont-headphone-alt"></i>
              <h4>Cross Platfrom</h4>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text</p>
            </div>
          </div>
        </div>
      </div>
    </section><!-- about section end -->
    <!-- feature section start -->
    <section class="feature-area ptb-90" id="feature">
      <div class="container">
        <div class="row flexbox-center">
          <div class="col-lg-4">
            <div class="single-feature-box text-lg-right text-center">
              <ul>
                <li>
                  <div class="feature-box-info">
                    <h4>Unlimited Features</h4>
                    <p>Lorem ipsum dolor amet consectetur adipisicing eiusmod </p>
                  </div>
                  <div class="feature-box-icon">
                    <i class="icofont icofont-brush"></i>
                  </div>
                </li>
                <li>
                  <div class="feature-box-info">
                    <h4>Responsive Design</h4>
                    <p>Lorem ipsum dolor amet consectetur adipisicing eiusmod </p>
                  </div>
                  <div class="feature-box-icon">
                    <i class="icofont icofont-computer"></i>
                  </div>
                </li>
                <li>
                  <div class="feature-box-info">
                    <h4>Well Documented</h4>
                    <p>Lorem ipsum dolor amet consectetur adipisicing eiusmod </p>
                  </div>
                  <div class="feature-box-icon">
                    <i class="icofont icofont-law-document"></i>
                  </div>
                </li>
                <li>
                  <div class="feature-box-info">
                    <h4>Full Free Chat</h4>
                    <p>Lorem ipsum dolor amet consectetur adipisicing eiusmod </p>
                  </div>
                  <div class="feature-box-icon">
                    <i class="icofont icofont-heart-beat"></i>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="single-feature-box text-center">
              <img src="img/feature.png" alt="feature">
            </div>
          </div>
          <div class="col-lg-4">
            <div class="single-feature-box text-lg-left text-center">
              <ul>
                <li>
                  <div class="feature-box-icon">
                    <i class="icofont icofont-eye"></i>
                  </div>
                  <div class="feature-box-info">
                    <h4>Retina ready</h4>
                    <p>Lorem ipsum dolor amet consectetur adipisicing eiusmod </p>
                  </div>
                </li>
                <li>
                  <div class="feature-box-icon">
                    <i class="icofont icofont-sun-alt"></i>
                  </div>
                  <div class="feature-box-info">
                    <h4>High Resolution</h4>
                    <p>Lorem ipsum dolor amet consectetur adipisicing eiusmod </p>
                  </div>
                </li>
                <li>
                  <div class="feature-box-icon">
                    <i class="icofont icofont-code-alt"></i>
                  </div>
                  <div class="feature-box-info">
                    <h4>Clean Codes</h4>
                    <p>Lorem ipsum dolor amet consectetur adipisicing eiusmod </p>
                  </div>
                </li>
                <li>
                  <div class="feature-box-icon">
                    <i class="icofont icofont-headphone-alt"></i>
                  </div>
                  <div class="feature-box-info">
                    <h4>Helping Supports</h4>
                    <p>Lorem ipsum dolor amet consectetur adipisicing eiusmod </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section><!-- feature section end -->
    <!-- testimonial section start -->
    <section class="testimonial-area ptb-90">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="sec-title">
              <h2>Testimonials</h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8 offset-lg-2">
            <div class="testimonial-wrap">
              <div class="single-testimonial-box">
                <div class="author-img">
                  <img src="img/author/author1.jpg" alt="author" />
                </div>
                <h5>Mary Balogh</h5>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi  aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in </p>
                <div class="author-rating">
                  <i class="icofont icofont-star"></i>
                  <i class="icofont icofont-star"></i>
                  <i class="icofont icofont-star"></i>
                  <i class="icofont icofont-star"></i>
                  <i class="icofont icofont-star"></i>
                </div>
              </div>
              <div class="single-testimonial-box">
                <div class="author-img">
                  <img src="img/author/author2.jpg" alt="author" />
                </div>
                <h5>Mary Balogh</h5>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi  aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in </p>
                <div class="author-rating">
                  <i class="icofont icofont-star"></i>
                  <i class="icofont icofont-star"></i>
                  <i class="icofont icofont-star"></i>
                  <i class="icofont icofont-star"></i>
                  <i class="icofont icofont-star"></i>
                </div>
              </div>
              <div class="single-testimonial-box">
                <div class="author-img">
                  <img src="img/author/author2.jpg" alt="author" />
                </div>
                <h5>Mary Balogh</h5>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi  aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in </p>
                <div class="author-rating">
                  <i class="icofont icofont-star"></i>
                  <i class="icofont icofont-star"></i>
                  <i class="icofont icofont-star"></i>
                  <i class="icofont icofont-star"></i>
                  <i class="icofont icofont-star"></i>
                </div>
              </div>
            </div>
            <div class="testimonial-thumb">
              <div class="thumb-prev">
                <div class="author-img">
                  <img src="img/author/author2.jpg" alt="author" />
                </div>
              </div>
              <div class="thumb-next">
                <div class="author-img">
                  <img src="img/author/author2.jpg" alt="author" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section><!-- testimonial section end -->
    <!-- download section start -->
    <section class="download-area ptb-90">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="sec-title">
              <h2>Download Available</h2>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <ul>
              <li>
                <a href="#" class="download-btn flexbox-center">
                  <div class="download-btn-icon">
                    <i class="icofont icofont-brand-android-robot"></i>
                  </div>
                  <div class="download-btn-text">
                    <p>Available on</p>
                    <h4>Android Store</h4>
                  </div>
                </a>
              </li>
              <li>
                <a href="#" class="download-btn flexbox-center">
                  <div class="download-btn-icon">
                    <i class="icofont icofont-brand-apple"></i>
                  </div>
                  <div class="download-btn-text">
                    <p>Available on</p>
                    <h4>Apple Store</h4>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <!-- download section end -->
    <!-- blog section start -->
    <!-- google map area start -->
    <div class="google-map"></div>
    <!-- google map area end -->
    <!-- footer section start -->
    <!-- footer section start -->
    <footer class="footer" id="contact">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="contact-form">
              <h4>Get in Touch</h4>
              <p class="form-message"></p>
              <form id="contact-form" action="#" method="POST">
                <input type="text" name="name" placeholder="Enter Your Name">
                <input type="email" name="email" placeholder="Enter Your Email">
                <input type="text" name="subject" placeholder="Your Subject">
                <textarea placeholder="Messege" name="message"></textarea>
                <button type="submit" name="submit">Send Message</button>
              </form>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="contact-address">
              <h4>Address</h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud</p>
              <ul>
                <li>
                  <div class="contact-address-icon">
                    <i class="icofont icofont-headphone-alt"></i>
                  </div>
                  <div class="contact-address-info">
                    <a href="callto:#">+8801712435941</a>
                    <a href="callto:#">+881934180093</a>
                  </div>
                </li>
                <li>
                  <div class="contact-address-icon">
                    <i class="icofont icofont-envelope"></i>
                  </div>
                  <div class="contact-address-info">
                    <a href="mailto:#">Jsoftbd87gmail.com</a>
                  </div>
                </li>
                <li>
                  <div class="contact-address-icon">
                    <i class="icofont icofont-web"></i>
                  </div>
                  <div class="contact-address-info">
                    <a href="www.jsoftbd.com">www.jsoftbd.com</a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="subscribe-form">
              <form action="#">
                <input type="text" placeholder="Your email address here">
                <button type="submit">Subcribe</button>
              </form>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="copyright-area">
              <ul>
                <li><a href="#"><i class="icofont icofont-social-facebook"></i></a></li>
                <li><a href="#"><i class="icofont icofont-social-twitter"></i></a></li>
                <li><a href="#"><i class="icofont icofont-brand-linkedin"></i></a></li>
              </ul>
              <p>
                &copy;
                Copyright &copy;
                All rights reserved
                <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. -->
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer><!-- footer section end -->
    <a href="#" class="scrollToTop">
      <i class="icofont icofont-arrow-up"></i>
    </a>
  </div>
</template>

<script>
  export default {
    data() {
      return {
      }
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped src="./../assets/css/bootstrap.min.css"></style>
<style scoped src="./../assets/css/slicknav.min.css"></style>
<style scoped src="./../assets/css/style.css"></style>
<style scoped src="./../assets/css/site.css"></style>
<style scoped>
  h3 {
    margin: 40px 0 0;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  li {
    display: inline-block;
    margin: 0 10px;
  }

  a {
    color: #42b983;
  }
</style>
