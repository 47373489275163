import { createWebHistory, createRouter } from "vue-router";

import Home from "@/components/Home.vue";
import AdminLayout from "@/components/AdminLayout"
import ActivateUser from "@/components/ActivateUser";
import ActivationInfo from "@/components/ActivationInfo";
import Dashboard from "@/components/Dashboard";
import Login from "@/components/Login";
import Users from "@/components/UserInfo";
import HouseInfo from "@/components/HouseInfo";
import Upload from "@/components/Upload";
import Logout from "@/components/Logout";

import store from "@/store";

const AppRoutes = []

const routes = [
  ...AppRoutes,
  {
    path: "/:catchAll(.*)",
    redirect: { name: 'Home' },
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { requiredAuth: false }
  },
  {
    path: "/ActivateUser/:id",
    name: "ActivateUser",
    component: ActivateUser,
    meta: { requiredAuth: false }
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { requiredAuth: false }
  },
  {
    path: '/admin',
    component: AdminLayout,
    redirect: { name: 'Dashboard' },
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: Dashboard,
        meta: { requiredAuth: true },
      },
      {
        path: "UserInfo",
        name: "UserInfo",
        component: Users,
        meta: { requiredAuth: true }
      },
      {
        path: "HouseInfo",
        name: "HouseInfo",
        component: HouseInfo,
        meta: { requiredAuth: true }
      },
      {
        path: "ActivationInfo",
        name: "ActivationInfo",
        component: ActivationInfo,
        meta: { requiredAuth: true }
      },
      {
        path: "Upload",
        name: "Upload",
        component: Upload,
        meta: { requiredAuth: true }
      },
      {
        path: "signout",
        name: "signout",
        component: Logout,
        meta: { requiredAuth: true }
      },
    ],
  },

];

export const routeConfig = createRouter({
  history: createWebHistory(),
  routes: routes,
});

routeConfig.beforeEach((to, from, next) => {
  //console.log(store.getters["auth/getAuthData"].token);
  if (!store.getters["auth/getAuthData"].token) {
    const access_token = localStorage.getItem("access_token");
    const refresh_token = localStorage.getItem("refresh_token");
    if (access_token) {
      const data = {
        access_token: access_token,
        refresh_token: refresh_token
      };
      store.commit('auth/saveTokenData', data);
    }
  }
  const auth = store.getters["auth/isTokenActive"];
  if (to.fullPath == "/") {
    return next();
  }
  else if (auth && !to.meta.requiredAuth) {
    return next();
  }
  else if (!auth && to.meta.requiredAuth) {
    return next({ path: '/login' });
  }

  return next();
});

export default routeConfig;