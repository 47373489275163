import jwtInterceptor from '../shared/jwtInterceptor';

export default {
  async GetAllHouses() {
    return await jwtInterceptor.get('/admin/GetAllHouse')
  },
  async GetHouseUsers(id) {
    return await jwtInterceptor.post('/admin/GetHouseUsers', { 'ID': id })
  },
  HouseInfoUpdate(values) {
    return jwtInterceptor.post('/admin/HouseInfoUpdate', values)
    },
    HouseInfoAdd(values) {
        return jwtInterceptor.post('/admin/HouseInfoAdd', values)
    },
  async UpdateUser(val) {
    return jwtInterceptor.post('/admin/UpdateUser', val)
  },
  async GetHomeConnections(value) {
    return jwtInterceptor.post('/admin/GetSRConnectionsByHouse', { 'ID': value })
  },
  async DeleteConn(id) {
    return await jwtInterceptor.post('/admin/DeleteConnection', { 'ID': id })
  },
  async GetAllUsers() {
    return await jwtInterceptor.get('/admin/GetAllUser')
  },
  async GetPackageList() {
    return await jwtInterceptor.get('/update/GetPackageList')
  },
  async DeletePackageFile(id) {
    return await jwtInterceptor.post('/update/DeletePackage', { 'ID': id })
  },
  async SavePackage(data) {
    return await jwtInterceptor.post('/update/SavePackage', data);
  },
  async Download(version) {
    return await jwtInterceptor.post('/update/Download', { 'Version': version }, { responseType: 'blob' })
  },
  async logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
  },
  async ActivateUser(id) {
    return await jwtInterceptor.post('/user/GetUserActivation', { 'ID': id })
  },
  async ActUserInfo() {
    return await jwtInterceptor.post('/user/GetUserActivationAll')
  },
  async ResetPassword(data) {
    return await jwtInterceptor.post('/user/ResetPassword', data);
    },
    async DeleteActivatedUser(id) {
        return await jwtInterceptor.post('/user/DeleteActivatedUser', { 'ActivationID':id });
    },
    async SendActivateEmail(id) {
        return await jwtInterceptor.post('/admin/SendActivateEmail', { 'ActivationID': id });
    }
}