import { render } from "./Home.vue?vue&type=template&id=38980878&scoped=true"
import script from "./Home.vue?vue&type=script&lang=js"
export * from "./Home.vue?vue&type=script&lang=js"

import "./../assets/css/bootstrap.min.css?vue&type=style&index=0&id=38980878&scoped=true&lang=css"
import "./../assets/css/slicknav.min.css?vue&type=style&index=1&id=38980878&scoped=true&lang=css"
import "./../assets/css/style.css?vue&type=style&index=2&id=38980878&scoped=true&lang=css"
import "./../assets/css/site.css?vue&type=style&index=3&id=38980878&scoped=true&lang=css"
import "./Home.vue?vue&type=style&index=4&id=38980878&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-38980878"

export default script