<template>
    <DataTable :value="houseInfoDatas" class="p-datatable-houseInfo" selectionMode="single" v-model:selection="selectedrow"
               responsiveLayout="scroll"
               dataKey="houseID" @rowSelect="houseInfoRowSelected"
               :paginator="true" :rows="5" :filters="filters" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
               :rowsPerPageOptions="[5,10,15]" :loading="isHouseLoading" sortField="houseNumber" :sortOrder="1">
        
        <template #header>
            <div class="p-d-flex p-jc-between p-ai-center">
                <h4 class="p-m-0">House Information</h4>
                <span class="p-input-icon-left">
                    <i class="pi pi-search" />
                    <!--<InputText placeholder="Keyword Search" />-->
                    <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                </span>
            </div>
        </template>
        <template #footer>
            <div class="p-d-flex p-jc-between p-ai-center">
                <Button class="p-m-0 p-button-raised" @click="AddInfo">ADD</Button>
                <span class="p-input-icon-left">
                    <Button class="p-button-raised" @click="RefreshInfo">Refresh</Button>
                </span>
            </div>
        </template>
        <template #empty>
            No house information found.
        </template>
        <template #loading>
            Loading house information data. Please wait.
        </template>
        <Column field="houseID" header="House ID" :sortable="true"></Column>
        <Column field="houseNumber" header="House Number" :sortable="true"></Column>
        <Column field="houseSqFeet" header="House sq.feet" :sortable="true"></Column>
        <Column field="isActive" header="Active" :sortable="true" headerStyle="width: 8%; text-align: center;" bodyStyle="text-align: center;">
            <template #body="slotProps">
                <span :class="'housedata-badge status-' + slotProps.data.isActive">{{$filters.formatActiveState(slotProps.data.isActive)}}</span>
            </template>
        </Column>
        <Column field="isDeviceConnected" header="Device Connected" :sortable="true" headerStyle="width: 12%; text-align: center;" bodyStyle="text-align: center;">
            <template #body="slotProps">
                <span :class="'housedata-badge status-' + slotProps.data.isDeviceConnected">{{$filters.formatdDeviceState(slotProps.data.isDeviceConnected)}}</span>
            </template>
        </Column>
        <Column field="createdOn" header="Created On" :sortable="true" dataType="date" headerStyle="width: 12%">
            <template #body="slotProps">
                {{$filters.formatDate(slotProps.data.createdOn)}}
            </template>
        </Column>
        <Column field="updatedOn" header="Updated On" :sortable="true" dataType="date" headerStyle="width: 12%">
            <template #body="slotProps">
                {{$filters.formatDate(slotProps.data.updatedOn)}}
            </template>
        </Column>
    </DataTable>
    <br />

    <Panel v-if="selectedHouse !== null" header="House Details">
        <form @submit.prevent>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-4">
                    <label for="HouseID">House ID</label>
                    <div v-if="add_Info">
                        <InputText type="text" class="form-control" id="HouseID" v-model="selectedHouse.houseID" />
                    </div>
                    <div v-else><InputText type="text" class="form-control" id="HouseID" v-model="selectedHouse.houseID" readonly /></div>
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label for="buildingName">Building Name</label>
                    <InputText type="text" class="form-control" id="buildingName" v-model="selectedHouse.buildingName" />
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label for="HouseNumber">House Number</label>
                    <InputText type="text" class="form-control" id="HouseNumber" v-model="selectedHouse.houseNumber" />
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label for="houseSqFeet">Sq Feet</label>
                    <InputText type="text" class="form-control" id="houseSqFeet" v-model="selectedHouse.houseSqFeet" />
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label for="ebConsumerNo">Electricity Consumer No.</label>
                    <InputText type="text" class="form-control" id="ebConsumerNo" v-model="selectedHouse.ebConsumerNo" />
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label for="waterConsumerNo">Watter Consumer No.</label>
                    <InputText type="text" class="form-control" id="waterConsumerNo" v-model="selectedHouse.waterConsumerNo" />
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label for="Active">Active</label>
                    <Dropdown class="form-control" id="Active" placeholder="Select the State" :options="state" optionValue="code" dataKey="value" optionLabel="name" v-model="selectedHouse.isActive" />
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label for="currentVersion">Current Version.</label>
                    <InputText type="text" class="form-control" id="currentVersion" v-model="selectedHouse.currentVersion" />
                </div>
                <div class="p-field p-col-12 p-md-4">
                    <label for="availableVersion">Available Version.</label>
                    <InputText type="text" class="form-control" id="availableVersion" v-model="selectedHouse.availableVersion" />
                </div>
                <div class="p-col-6 p-md-4">
                    <div v-if="add_Info">
                        <Button class="p-button-raised" @click="HouseInfoAdd()" label="Add"></Button>
                    </div>
                    <div v-else><Button class="p-button-raised" @click="HouseInfoUpdate()" label="Update"></Button></div>
                </div>
                <div class="p-col-6 p-md-4">
                    <Button class="p-button-raised" @click="HouseInfoCancel()" label="Cancel"></Button>
                </div>
                
                <div class="p-col-6 p-md-4">
                    <div v-if="add_Info">
                        <Button class="p-button-raised" @click="HouseInfoClear()" label="Clear"></Button>
                    </div>
                </div>
                </div>
</form>
    </Panel>
    
    <br />
    <HouseDetails :house="selectedHouse" v-if="selectedHouse !== null"></HouseDetails>

</template>

<script>
  import HouseDetails from '../components/HouseDetails.vue'
  import PostService from '../services/post-services';
  import { FilterMatchMode } from 'primevue/api';

    export default {
        name: "HouseInfo",
        components: {
            HouseDetails,
        },
        data() {
            return {
                isHouseLoading: true,
                state: [{ name: 'Active', code: 'true' }, { name: 'In-Active', code: 'false' }],
                selectedHouse: null,
                selectedrow: null,
                houseInfoDatas: null,
                add_Info: false,
                filters: {},
            };
        },
        created() {
            this.initFilters();
        },
        mounted() {
            this.getAllHouses();
        },
        methods: {
            getAllHouses() {
                this.add_Info = false;
                PostService.GetAllHouses().then(res => {
                    this.houseInfoDatas = res.data;
                    this.houseInfoDatas.forEach(house => { house.createdOn = new Date(house.createdOn); house.updatedOn = new Date(house.updatedOn) });
                }).catch(function (error) {
                    alert("some error for getting data", error);
                });
                this.isHouseLoading = false;
            },
            houseInfoRowSelected(event) {
                this.add_Info = false;
                this.selectedHouse = JSON.parse(JSON.stringify(event.data))
            },
            HouseInfoCancel() {
                this.selectedHouse = null;
            },
            HouseInfoClear() {
                this.AddInfo();
            },
            RefreshInfo() {
                this.getAllHouses();
                alert("Refreshed");
            },
            AddInfo() {
                this.add_Info = true;
                this.selectedHouse = JSON.parse(JSON.stringify({
                    "houseID": null,
                    "houseNumber": null,
                    "houseSqFeet": null,
                    "ebConsumerNo": null,
                    "waterConsumerNo": null,
                    "buildingName": null,
                    "isActive": false,
                    "isDeviceConnected": false,
                    "createdOn": new Date,
                    "updatedOn": new Date,
                    "currentVersion": null,
                    "availableVersion": null,
                    "updateStatus": null
                }));
                if (this.selectedHouse.houseID != null)
                    alert(this.selectedHouse.houseID);
            },
            HouseInfoUpdate() {
                if (this.selectedHouse.isActive == "true") {
                    this.selectedHouse.isActive = true;
                }
                else {
                    this.selectedHouse.isActive = false;
                }
                PostService.HouseInfoUpdate(this.selectedHouse).then(res => {
                    alert("updated", res);
                    this.selectedHouse = null;
                    this.getAllHouses();
                }).catch(function (error) {
                    alert("error updating house info table", error);
                });
            },
            HouseInfoAdd() {
                if (this.selectedHouse.isActive == "true") {
                    this.selectedHouse.isActive = true;
                }
                else {
                    this.selectedHouse.isActive = false;
                }

                PostService.HouseInfoAdd(this.selectedHouse).then(res => {
                    alert("Added", res);
                    this.selectedHouse = null;
                    this.getAllHouses();
                    this.add_Info = false;
                }).catch(function (error) {
                    alert("error updating house info table", error);
                });
            },
            initFilters() {
                this.filters = {
                    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
                }
            },

        },

    };
</script>

<style scoped>
    .circle {
        border-radius: 50%;
        width: 50px;
        height: 50px;
        background-color: rgb(44,108,128)
    }

        .circle::before {
            content: "+";
            height: 40px;
            width: 40px;
            font-size: 60px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            font-family: courier;
            color: white;
        }
  > > > .p-paginator .p-paginator-current {
    margin-left: auto;
  }
</style>
<style>
  .p-button {
    padding: 0.329rem .5rem !important;
    font-size: .8rem !important;
  }

  .p-link {
    font-size: .8rem !important;
  }

  .p-datatable .p-datatable-header {
    padding: 0.257rem 1rem !important;
  }

  .p-panel .p-panel-header {
    padding: 0.557rem 1rem !important;
  }

  .p-component {
    font-size: .8rem !important;
  }

  .p-datatable .p-datatable-tbody > tr > td {
    padding: 0.471rem 0.457rem !important;
  }

  .p-datatable .p-datatable-thead > tr > th {
    padding: 0.471rem 0.457rem !important;
    font-weight: 600 !important;
  }

  .p-inputtext {
    font-size: .85rem !important;
    /*    padding: 0.229rem .229rem;
  */
  }

  .p-field > label {
    margin-bottom: 0.1rem !important;
  }

  /*.p-field {
    margin-bottom: .5rem !important;
  }*/
</style>