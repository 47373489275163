<template>
  <div class="container-login" style="background-image: url('/img/bg-01.jpg')">
    <div class="col-lg-12">
      <div v-if="isActCompleted" class="sec-title mb-0">
        <Card>
          <template #title>
            Your account is activated successfully.
          </template>
          <template #content>
            Please wait for few minutes before attempting to login to the mobile device. We are Synchronizing your account with the Home Automation System.
          </template>
          <template #footer>
            <div style="text-align:center">
              <Button icon="pi pi-times" label="Close" class="p-button-secondary" @click="handleBack" />
            </div>
          </template>
        </Card>
      </div>
    </div>
    <div class="col-lg-12">
      <div v-if="isActive" class="sec-title mb-0">
        <Card>
          <template #title>
            Your Account already Activated.
          </template>
          <template #content>
            Please login to the mobile device with your password to access the Home Automation System.
          </template>
          <template #footer>
            <div style="text-align:center">
              <Button icon="pi pi-times" label="Back" class="p-button-secondary" @click="handleBack" />
            </div>
          </template>
        </Card>
      </div>
    </div>
    <div v-if="responseError" class="col-lg-12">
      <div class="sec-title mb-0">
        <Card>
          <template #title>
            Your Activation code is expired or invalid.
          </template>
          <template #content>
            Please check your activation code.
          </template>
          <template #footer>
            <div style="text-align:center">
              <Button icon="pi pi-times" label="Back" class="p-button-secondary" @click="handleBack" />
            </div>
          </template>
        </Card>
      </div>
    </div>
    <div v-if="showActPnl">
      <div class="p-d-flex p-jc-center">
        <div class="card">
          <h3>Activate Account</h3>
          <form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">
            <div class="p-field">
              <div class="p-float-label p-input-icon-right">
                <i class="pi pi-envelope" />
                <InputText id="emailId" v-model="userDetails.userEmail" disabled />
                <label for="email">Email*</label>
              </div>
            </div>
            <div class="p-field">
              <div class="p-float-label">
                <Password id="password" v-model="form.password" :class="{'p-invalid': v$.password.$invalid && submitted}" :feedback="false" toggleMask>
                </Password>
                <label for="password" :class="{'p-error': v$.password.$invalid && submitted}">Password*</label>
              </div>
              <span v-if="v$.password.$error && submitted">
                <span id="email-error" v-for="(error, index) of v$.password.$errors" :key="index">
                  <small class="p-error">{{error.$message}}</small>
                </span>
              </span>
              <small v-else-if="(v$.password.$invalid && submitted) || v$.password.$pending.$response" class="p-error">{{v$.password.required.$message.replace('Value', 'Password')}}</small>
            </div>
            <div class="p-field">
              <div class="p-float-label">
                <Password id="repeatPassword" v-model="form.repeatPassword" :class="{'p-invalid':v$.repeatPassword.$invalid && submitted}" :feedback="false" toggleMask></Password>
                <label for="repeatPassword" :class="{'p-error':v$.repeatPassword.$invalid && submitted}">Confirm Password*</label>
              </div>
              <span v-if="v$.repeatPassword.$error && submitted">
                <span id="email-error" v-for="(error, index) of v$.repeatPassword.$errors" :key="index">
                  <small class="p-error">{{error.$message}}</small>
                </span>
              </span>
              <small v-else-if="(v$.repeatPassword.$invalid && submitted) || v$.repeatPassword.$pending.$response" class="p-error">{{v$.repeatPassword.sameAs.$message.replace('Value', 'Password')}}</small>
            </div>
            <Button type="submit" label="Submit" class="p-mt-2" />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import PostService from '../services/post-services';
  import useVuelidate from "@vuelidate/core";
  import { useRoute, useRouter } from 'vue-router';
  import { reactive, ref, toRefs, computed, onMounted } from "vue";
  import { required, minLength, sameAs } from "@vuelidate/validators";

  export default {
    name: "ActivateUser",
    setup() {
      const showActPnl = ref(false);
      const submitted = ref(false);
      const activationId = ref(null);
      const userDetails = ref(null);
      const router = useRouter();
      const route = useRoute();
      const isActCompleted = ref(false);
      const isActive = ref(false);
      const responseError = ref(false);

      const form = reactive({
        password: '',
        repeatPassword: '',
      });
      const { password } = toRefs(form, "form");

      const rules = computed(() => ({
        password: { required, minLength: minLength(8) },
        repeatPassword: { required, sameAs: sameAs(password) }
      }));

      const v$ = useVuelidate(rules, form);

      const handleSubmit = (isFormValid) => {
        submitted.value = true;

        if (!isFormValid || v$.value.$error) {
          return;
        }

        resetPassword(isFormValid);
      };

      const handleBack = () => {
        router.push({ name: 'Home' });
      };


      const getUserActivation = () => {
        activationId.value = route.params.id;

        PostService.ActivateUser(activationId.value).then(res => {
          userDetails.value = res.data;

          if (res.data != null) {
            resetPasswordDialog();
          }
          if (res.data.isActivated) {
            isActive.value = true;
            showActPnl.value = false;
          }
        }).catch(() => {
          responseError.value = true;
        });
      }

      const resetPasswordDialog = () => {
        showActPnl.value = true;
      }

      const resetPassword = (isFormValid) => {
        submitted.value = true;
        if (!isFormValid) {
          alert("form is not valid");
          return;
        }

        const formData = new FormData()
        formData.append('ActivationID', activationId.value)
        formData.append('UserEmail', userDetails.value.userEmail)
        formData.append('Password', password.value)

        PostService.ResetPassword(formData).then(res => {

          if (res.data != null) {
            alert("Password is saved")
            resetForm();
          }
          if (res.data.isActCompleted) {
            isActCompleted.value = true;
            showActPnl.value = false;
          }

          if (res.data == "invalid") {
            alert("Please change the password");
          }
        }).catch(function (error) {
          alert(error);
        });
      }

      const resetForm = () => {
        submitted.value = false;
        userDetails.value.userEmail = '';
        password.value = '';
      }

      onMounted(getUserActivation);
      return {
        submitted, showActPnl, userDetails, form, v$, handleSubmit, handleBack, password,
        isActCompleted, isActive, responseError
      };
    },
  }

</script>
<style scoped>

  .p-fluid {
    margin-top: 30px;
  }

  .container-login {
    width: 100%;
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .card {
    width: 500px;
    background-color: white;
    padding: 20px;
    border-radius: 25px;
    height: 330px;
  }
</style>