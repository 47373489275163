import { createApp } from 'vue'
import App from './App.vue'
import * as appRouter from './router';
import store from './store'
import PrimeVue from 'primevue/config';

import 'primevue/resources/themes/nova/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css';
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

import VueSidebarMenu from 'vue-sidebar-menu'
import TabMenu from 'primevue/tabmenu';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';     //optional for column grouping
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Card from 'primevue/card';
import Dialog from 'primevue/dialog';
import Password from 'primevue/password';
import Divider from 'primevue/divider';
import VuelidatePlugin from "@vuelidate/core";
import Dropdown from 'primevue/dropdown';
import Checkbox from 'primevue/checkbox';
import Panel from 'primevue/panel';
import Tooltip from 'primevue/tooltip';
import Toolbar from 'primevue/toolbar';
import FileUpload from 'primevue/fileupload';
import Avatar from 'primevue/avatar';
import ContextMenu from 'primevue/contextmenu';
import ToastService from 'primevue/toastservice';

import { Chart, registerables } from "chart.js";

const app = createApp(App)
app.use(appRouter.routeConfig)
app.use(store)
app.use(VuelidatePlugin)
app.use(PrimeVue, { ripple: true })
app.use(VueSidebarMenu)
app.use(ToastService);
app.component('ContextMenu', ContextMenu);
app.component('Avatar', Avatar);
app.component('Button', Button)
app.component('Card', Card)
app.component('Column', Column)
app.component('ColumnGroup', ColumnGroup)
app.component('Checkbox', Checkbox)
app.component('DataTable', DataTable)
app.component('Dialog', Dialog);
app.component('Divider', Divider)
app.component('Dropdown', Dropdown);
app.component('TabMenu', TabMenu);
app.component('Panel', Panel)
app.component('Password', Password)
app.component('FileUpload', FileUpload)
app.component('InputText', InputText)
app.component('Toolbar', Toolbar)
app.directive('tooltip', Tooltip);
Chart.register(...registerables);
app.mount('#app')

app.config.globalProperties.$filters = {
  formatdDeviceState(value) {
    if (value)
      return "Connected";
    else
      return "Disconnected";
  },
  formatDate(value) {
    try {
      if (value != '0001-01-01T00:00:00') {
        return value.toLocaleDateString('en-GB', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }) + ' ' + value.toLocaleTimeString('en-GB', {
          hour: '2-digit',
          minute: '2-digit'
        })
      }
      else {
        return "NA"
      }
    }
    catch(ex) {
      console.log(ex);
    }


  },
  formatActiveState(value) {
    if (value)
      return "Active";
    else
      return "In-Active";
  },
  formatYesNo(value) {
    if (value)
      return "Yes";
    else
      return "No";
  },
}